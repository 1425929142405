import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/tan_protect/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/tan-protect-gr.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/tan-protect-gr-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Ενισχύει το φυσικό μαύρισμα</h3>
                      <p>
                        Είτε απολαμβάνετε τον ήλιο κάποιο σαββατοκύριακο μακριά
                        από την πόλη, είτε σχεδιάζετε να αποδράσετε φεύγοντας
                        για χαλαρωτικές διακοπές στην παραλία για μεγάλο χρονικό
                        διάστημα, τα προϊόντα λαδιού και τα γαλακτώματα σε σπρέι
                        της σειράς PIZ BUIN TAN &amp; PROTECT<sup>®</sup> θα σας
                        βοηθήσουν να απολαύσετε στο έπακρο τις στιγμές κάτω από
                        τον ήλιο. Η σειρά PIZ BUIN TAN &amp; PROTECT<sup>®</sup>{" "}
                        συνδυάζει την αποτελεσματική προστασία κατά των ακτίνων
                        UVA/UVB με το συστατικό Illumitone™. Αυξάνει την
                        παραγωγή χρωστικών ουσιών για μαύρισμα έως και 70%.* Το
                        συστατικό Illumitone™ έχει αποδειχθεί ότι επιταχύνει τη
                        φυσική διαδικασία μαυρίσματος** - για ένα φυσικά πιο
                        γρήγορο***, πιο όμορφο μαύρισμα.
                      </p>
                      <p className="footNote">
                        *Σπρέι και λοσιόν, δοκιμασμένα in vitro ** Δοκιμή in
                        vitro ***Περιέχει τεχνολογία ενίσχυσης του φυσικού
                        μαυρίσματος{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="tan-accelerating-oil-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-oil-spray-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TAN ACCELERATING OIL SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Το λάδι σε μορφή σπρέι για επιτάχυνση του μαυρίσματος
                          σας βοηθά να αποκτήσετε ασφαλές και υπέροχο χρώμα
                          γρήγορα – δίνοντάς σας αυτό το καλοκαίρι την ευκαιρία
                          να απολαύσετε κάθε στιγμή σας κάτω από τον ήλιο. Τα
                          παραδοσιακά λάδια μαυρίσματος μπορεί να κάνουν το
                          μαύρισμά σας να φαίνεται φανταστικό, αλλά προσφέρουν
                          ελάχιστη ή καθόλου προστασία, ενώ με τις κοινές
                          αντηλιακές κρέμες με υψηλό SPF συνήθως χρειάζεται να
                          περιμένετε πολύ καιρό για να αποκτήσετε το καλοκαιρινό
                          χρώμα που επιθυμείτε. Επομένως, πώς μπορείτε να
                          αποκτήσετε ένα υπέροχο, φυσικό μαύρισμα πιο γρήγορα,
                          χωρίς να μειώνετε την προστασία του δέρματός σας;
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Το προϊόν της σειράς PIZ BUIN TAN &amp; PROTECT
                            <sup>®</sup>, Tan Accelerating Oil Spray, είναι
                            ακριβώς αυτό που χρειάζεστε. Το γρήγορης
                            απορρόφησης, μη-λιπαρό και εύκολο στην εφαρμογή λάδι
                            συνδυάζει την αποτελεσματική προστασία από τις
                            ακτίνες UVA και UVB με το melitane, ένα πρωτοποριακό
                            συστατικό που επιταχύνει τη διαδικασία του φυσικού
                            μαυρίσματος του σώματος*. Το γρήγορης απορρόφησης
                            λάδι θρέφει το δέρμα, αφήνοντάς το απαλό και
                            λαμπερό.
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Tan
                            Accelerating Oil Spray.
                          </p>
                          <p>
                            Προσφέρει ένα γρηγορότερο και πιο όμορφο μαύρισμα .
                            . . Με ασφάλεια!
                          </p>
                          <p>Είναι ανθεκτικό στο νερό και τον ιδρώτα.</p>
                          <p>Έχει την προτίμηση των καταναλωτών.</p>
                          <p>
                            Ζητήσαμε από 102 καταναλωτές να μας πουν τη γνώμη
                            τους για το σπρέι λαδιού επιτάχυνσης μαυρίσματος**.
                            Μετά από τέσσερις εβδομάδες δοκιμών…
                          </p>
                          <p>
                            <strong className="orange">Το 80%</strong> των
                            καταναλωτών είδαν:
                          </p>
                          <ul>
                            <li>Ενισχυμένο φυσικό μαύρισμα</li>
                          </ul>
                          <p>
                            <strong className="orange">Το 84%</strong> των
                            καταναλωτών παρατήρησαν:
                          </p>
                          <p>Όμορφη, γυαλιστερή λάμψη</p>
                          <p>
                            <strong className="orange">Το 97%</strong> των
                            καταναλωτώνείπαν ότι τους διασφάλισε :
                          </p>
                          <ul>
                            <li>Αποτελεσματική προστασία από τον ήλιο</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Διαβάστε περισσότερα
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Απλώστε επαρκή ποσότητα ομοιόμορφα πριν την έκθεση
                          στον ήλιο. Η μείωση της ενδεδειγμένης ποσότητας
                          περιορίζει σημαντικά το επίπεδο προστασίας.
                          Επαναλάβετε συχνά την εφαρμογή, ιδιαίτερα μετά από
                          εφίδρωση, κολύμπι ή σκούπισμα με πετσέτα. Αποφύγετε
                          τον μεσημεριανό ήλιο και την παρατεταμένη έκθεση στον
                          ήλιο ακόμα και με τη χρήση αντηλιακού. Κρατήστε τα
                          βρέφη και τα παιδιά μακριά από την απευθείας έκθεση
                          στην άμεση ακτινοβολία του ήλιου.
                        </p>
                        <p>
                          Μην ψεκάζετε απευθείας στο πρόσωπο. Για χρήση στο
                          πρόσωπο, ψεκάστε στα χέρια σας και απλώστε
                          αποφεύγοντας την επαφή με τα μάτια.
                        </p>
                        <p className="footNote">*In vitro δοκιμές</p>
                        <p className="footNote">
                          **Αποτελέσματα αυτοαξιολόγησης που ελήφθησαν για το
                          SPF 30 Sun Spray,102 συμμετέχοντες, εφαρμογή
                          τουλάχιστον μία φορά την ημέρα για διάστημα 4
                          εβδομάδων.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Προηγμένο αντηλιακό σύστημα φίλτρων κατά των
                                ακτίνων UVA/UVB PIZ BUIN®
                                <div className="textBlock bottom">
                                  <p>
                                    Ως ειδικοί στην αντηλιακή φροντίδα,
                                    χρησιμοποιούμε καινοτόμες τεχνολογίες για να
                                    εξασφαλίσουμε ότι επιτυγχάνεται η σωστή
                                    ισορροπία μαυρίσματος και προστασίας όταν
                                    απολαμβάνετε τον ήλιο.
                                  </p>
                                  <p>
                                    Κάθε προηγμένο αντηλιακό σύστημα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN
                                    <sup>®</sup> είναι μία ισχυρή τεχνολογία
                                    προστασίας από τον ήλιο που έχει αναπτυχθεί
                                    από την Kenvue. Προσφέρουν
                                    αποτελεσματική, φωτοσταθερή και ευρέως
                                    φάσματος προστασία κατά των ακτίνων UVA και
                                    UVB, που δηλώνει ότι τα αντηλιακά PIZ BUIN
                                    <sup>®</sup> είναι σύμφωνα με τους ισχύοντες
                                    ευρωπαϊκούς κανονισμούς.
                                  </p>
                                  <p>
                                    Όλα τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN®
                                    συμβάλλουν στην προστασία από την υπεριώδη
                                    ακτινοβολία UVA και UVB.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVB - «οι ακτίνες που προκαλούν
                                    έγκαυμα» - βλάπτουν την επιδερμίδα και είναι
                                    η κύρια αιτία του ηλιακού εγκαύματος.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVA – «ακτίνες γήρανσης» -
                                    εισχωρούν βαθύτερα και καταστρέφουν το
                                    κολλαγόνο και την ελαστίνη, το οποίο μπορεί
                                    να οδηγήσει σε πρόωρη γήρανση του δέρματος
                                    και καρκίνο.
                                  </p>
                                  <p>
                                    Τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN® περιέχουν
                                    φίλτρα και για τους δύο τύπους ακτίνων (UVA
                                    και UVB) για την προστασία ενάντια στον ήλιο
                                    και τη φθορά του δέρματος μακροπρόθεσμα, ενώ
                                    παράλληλα προστατεύουν από τις πλέον σοβαρές
                                    επιπτώσεις της υπεριώδους ακτινοβολίας UVA.
                                    Καλύπτουν το μεγαλύτερο μέρος του φάσματος
                                    της υπεριώδους ακτινοβολίας UVA / UVB,
                                    προσφέροντας ευρεία προστασία στο δέρμα σας.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                ΒΙΤΑΜIΝΗ Ε
                                <div className="textBlock bottom">
                                  <p>
                                    Η βιταμίνη Ε είναι ένα ισχυρό φυσικό
                                    αντιοξειδωτικό που βοηθά στην προστασία από
                                    τις ελεύθερες ρίζες, οι οποίες καταστρέφουν
                                    τα κύτταρα και προκαλούν πρόωρη γήρανση του
                                    δέρματος.
                                  </p>
                                  <p>
                                    Η βιταμίνη E περιέχεται στο PIZ BUIN
                                    <sup>®</sup>
                                  </p>
                                  <p>
                                    After Sun Tan Intensifying Moisturising
                                    Lotion.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Το συστατικό Illumitone™ έχει αποδειχθεί ότι
                                    ενισχύει τη φυσική διαδικασία του
                                    μαυρίσματος. Αυξάνει την παραγωγή των
                                    φυσικών χρωστικών ουσιών μαυρίσματος του
                                    οργανισμού (μελανίνη) έως και 70%*. To
                                    Illumitone™ δε λεκιάζει τα ρούχα, τα μαγιό ή
                                    τις πετσέτες.
                                  </p>
                                  <p className="footNote">
                                    *Σπρέι και λοσιόν δοκιμασμένα in vitro
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="tan-intensifying-sun-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-spray-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TAN INTENSIFYING SUN SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Υψηλή Προστασία - Με Illumitone™ που επιταχύνει τη
                          φυσική διαδικασία μαυρίσματος*. Προστατεύει άμεσα.
                          Απορροφάται αμέσως. Ανθεκτικό στο νερό. Σημείωση: Δεν
                          περιέχει ουσίες αυτομαυρίσματος.{" "}
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor30" />
						<span className="factor factor15" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ: Εφαρμόστε ομοιόμορφα μια γενναιόδωρη
                          ποσότητα πριν την έκθεση στον ήλιο. Μειωμένη ποσότητα
                          μειώνει σημαντικά το επίπεδο προστασίας. Επαναλάβετε
                          συχνά την εφαρμογή, ειδικά μετά από εφίδρωση, κολύμπι
                          και σκούπισμα με πετσέτα. Aποφύγετε τον μεσημεριανό
                          ήλιο και την παρατεταμένη παραμονή, ακόμα και με την
                          χρήση αντηλιακού. Κρατήστε βρέφη και παιδιά μακριά από
                          την απευθείας έκθεση στην άμεση ακτινοβολία του ήλιου.
                          Για εφαρμογή στο πρόσωπο μην ψεκάζετε απευθείας αλλά
                          εφαρμόστε πρώτα στις παλάμες και επαλείψτε. Προσοχή
                          για τυχόν υπολείμματα του προϊόντος στο πάτωμα, καθώς
                          ενδέχεται να προκαλέσουν ολισθηρότητα.{" "}
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Προηγμένο αντηλιακό σύστημα φίλτρων κατά των
                                ακτίνων UVA/UVB PIZ BUIN®
                                <div className="textBlock bottom">
                                  <p>
                                    Ως ειδικοί στην αντηλιακή φροντίδα,
                                    χρησιμοποιούμε καινοτόμες τεχνολογίες για να
                                    εξασφαλίσουμε ότι επιτυγχάνεται η σωστή
                                    ισορροπία μαυρίσματος και προστασίας όταν
                                    απολαμβάνετε τον ήλιο.
                                  </p>
                                  <p>
                                    Κάθε προηγμένο αντηλιακό σύστημα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN
                                    <sup>®</sup> είναι μία ισχυρή τεχνολογία
                                    προστασίας από τον ήλιο που έχει αναπτυχθεί
                                    από την Kenvue. Προσφέρουν
                                    αποτελεσματική, φωτοσταθερή και ευρέως
                                    φάσματος προστασία κατά των ακτίνων UVA και
                                    UVB, που δηλώνει ότι τα αντηλιακά PIZ BUIN
                                    <sup>®</sup> είναι σύμφωνα με τους ισχύοντες
                                    ευρωπαϊκούς κανονισμούς.
                                  </p>
                                  <p>
                                    Όλα τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN®
                                    συμβάλλουν στην προστασία από την υπεριώδη
                                    ακτινοβολία UVA και UVB.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVB - «οι ακτίνες που προκαλούν
                                    έγκαυμα» - βλάπτουν την επιδερμίδα και είναι
                                    η κύρια αιτία του ηλιακού εγκαύματος.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVA – «ακτίνες γήρανσης» -
                                    εισχωρούν βαθύτερα και καταστρέφουν το
                                    κολλαγόνο και την ελαστίνη, το οποίο μπορεί
                                    να οδηγήσει σε πρόωρη γήρανση του δέρματος
                                    και καρκίνο.
                                  </p>
                                  <p>
                                    Τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN® περιέχουν
                                    φίλτρα και για τους δύο τύπους ακτίνων (UVA
                                    και UVB) για την προστασία ενάντια στον ήλιο
                                    και τη φθορά του δέρματος μακροπρόθεσμα, ενώ
                                    παράλληλα προστατεύουν από τις πλέον σοβαρές
                                    επιπτώσεις της υπεριώδους ακτινοβολίας UVA.
                                    Καλύπτουν το μεγαλύτερο μέρος του φάσματος
                                    της υπεριώδους ακτινοβολίας UVA / UVB,
                                    προσφέροντας ευρεία προστασία στο δέρμα σας.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                ΒΙΤΑΜIΝΗ Ε
                                <div className="textBlock bottom">
                                  <p>
                                    Η βιταμίνη Ε είναι ένα ισχυρό φυσικό
                                    αντιοξειδωτικό που βοηθά στην προστασία από
                                    τις ελεύθερες ρίζες, οι οποίες καταστρέφουν
                                    τα κύτταρα και προκαλούν πρόωρη γήρανση του
                                    δέρματος.
                                  </p>
                                  <p>
                                    Η βιταμίνη E περιέχεται στο PIZ BUIN
                                    <sup>®</sup>
                                  </p>
                                  <p>
                                    After Sun Tan Intensifying Moisturising
                                    Lotion.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Το συστατικό Illumitone™ έχει αποδειχθεί ότι
                                    ενισχύει τη φυσική διαδικασία του
                                    μαυρίσματος. Αυξάνει την παραγωγή των
                                    φυσικών χρωστικών ουσιών μαυρίσματος του
                                    οργανισμού (μελανίνη) έως και 70%*. To
                                    Illumitone™ δε λεκιάζει τα ρούχα, τα μαγιό ή
                                    τις πετσέτες.
                                  </p>
                                  <p className="footNote">
                                    *Σπρέι και λοσιόν δοκιμασμένα in vitro
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/gr/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/hydro-infusion/" target="_self">
                      Hydro Infusion
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/sensitive/" target="_self">
                      Sensitive
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/gr/our-products/tan_protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Διαβάστε περισσότερα');\n} else {\nbox.slideDown();\n$(this).text('Διαβάστε λιγότερα');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
